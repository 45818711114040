import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { GetCuratedClinVarVariantContentParams } from "./types";
import { VariantResult } from "../../models/variant-result";
import { SearchBarTerms } from "../../store/slices/searchSlice";
import { isGenomicReference, isRSID } from "../../utils/strings/regex";
import { getGeneFromVariantSuggestions } from "../../utils/variant";
import { RootState } from "../../store/store";

export const generateCuratedRouteParams = (
  params: GetCuratedClinVarVariantContentParams,
  queryApi: BaseQueryApi
) => {
  const variants = [...params.urlTermIds[SearchBarTerms.variant]];
  const genes = [...params.urlTermIds[SearchBarTerms.gene]];
  if (isGenomicReference(variants) || isRSID(variants)) {
    const reduxVariants = (queryApi.getState() as RootState).search.variant;
    for (let i = 0; i < variants.length; i++) {
      const v = variants[i];
      if (isGenomicReference([v]) || isRSID([v])) {
        const suggGene = getGeneFromVariantSuggestions(v, reduxVariants);
        if (suggGene && !genes.includes(suggGene)) {
          genes.push(suggGene);
        }
      }
    }
  }
  const casedGenes = genes.map((g) => VariantResult.geneCasing(g));
  const encodedVariants = variants.map((v) => encodeURIComponent(v));
  const variantString = `variant[]=${encodedVariants.join("&variant[]=")}`;
  const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;

  return {
    gene: geneString,
    variant: variantString,
  };
};
