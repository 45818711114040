import { VariantResult } from "../../models/variant-result";
import {
  SearchedTermIds,
  BooleanSearch,
  SearchBarTerms,
  initialSearchState,
} from "../../store/slices/searchSlice";

export const getReporterURL = (gene: string[], variants: string[]) => {
  const casedGenes = gene.map((g) => VariantResult.geneCasing(g));
  const variantString = `variant[]=${variants.join("&variant[]=")}`;
  const geneString = `gene[]=${casedGenes.join("&gene[]=")}`;
  return `/reporter/related?${[variantString, geneString].join("&")}`;
};

export const generateMutationBody = (
  urlSearchParams: SearchedTermIds,
  urlBooleanParams: BooleanSearch,
  urlCats: string[],
  urlSigTerms: string[],
  addlOptions: { gene: string[]; mutation: string[] } = {
    gene: [],
    mutation: [],
  }
) => {
  const gene =
    addlOptions.gene.length !== 0
      ? addlOptions.gene
      : urlSearchParams[SearchBarTerms.gene];
  const mutation =
    addlOptions.mutation.length !== 0
      ? addlOptions.mutation
      : urlSearchParams[SearchBarTerms.variant];
  return JSON.stringify({
    boolean: true,
    article_sort: "relevance",
    article_sort_asc: false,
    mutation_source: "supplemental",
    cnv_match_op: "intersects",
    article_list_filter: "",
    offset: 0,
    sig_terms: urlSigTerms,
    cats: urlCats,
    gene: gene,
    mutation: mutation,
    disease: urlSearchParams[SearchBarTerms.disease],
    keyword: urlSearchParams[SearchBarTerms.keyword],
    hpo: urlSearchParams[SearchBarTerms.hpo],
    unii: urlSearchParams[SearchBarTerms.unii],
    cnv: urlSearchParams[SearchBarTerms.cnv],
    gene_op:
      urlBooleanParams.gene ?? initialSearchState.booleans[SearchBarTerms.gene],
    disease_op:
      urlBooleanParams.disease ??
      initialSearchState.booleans[SearchBarTerms.disease],
    mutation_op:
      urlBooleanParams.variant ??
      initialSearchState.booleans[SearchBarTerms.variant],
    keyword_op:
      urlBooleanParams.keyword ??
      initialSearchState.booleans[SearchBarTerms.keyword],
    hpo_op:
      urlBooleanParams.hpo ?? initialSearchState.booleans[SearchBarTerms.hpo],
    unii_op:
      urlBooleanParams.unii ?? initialSearchState.booleans[SearchBarTerms.unii],
    cnv_op:
      urlBooleanParams.cnv ?? initialSearchState.booleans[SearchBarTerms.cnv],
    origin: "bookmark",
  });
};
