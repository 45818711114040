import { z } from "zod";
import { SearchedTermIds } from "../../store/slices/searchSlice";

// SCHEMA
const ACMGDescSchema = z.object({
  short_desc: z.string(),
});

export const RibbonSchema = z.object({
  record: z.optional(
    z.object({
      acmg_call: z.string(),
      disease: z.object({
        clingen: z.any(),
        display_name: z.string(),
        resources: z.any(),
        tags: z.any(),
        treatments: z.any(),
      }),
      json_record: z.record(ACMGDescSchema),
      review_date: z.string(),
      status: z.string(),
    })
  ),
});

export const ReporterCuratedDataSchema = z.object({
  curationRecords: z.object({
    curatedFor: z.string(),
    gene: z.string(),
    isEnterprise: z.boolean(),
    isSponsored: z.boolean(),
    variants: z.array(
      z.object({
        acmg_call: z.string(),
        article_count: z.number(),
        gene: z.string(),
        id: z.string(),
        type: z.string(),
      })
    ),
  }),
});

export const ReporterClinvarDataSchema = z.object({
  clinVarRecords: z.array(
    z.object({
      citations: z.number(),
      description: z.string(),
      displayName: z.string(),
      fullName: z.string(),
      id: z.string(),
      interpretation: z.string(),
      lastEvaluated: z.string(),
      mostRecent: z.string(),
      reviewStars: z.number(),
      reviewStatus: z.string(),
      submissions: z.number(),
      submitterCalls: z.array(
        z.object({
          call: z.string(),
          submitter: z.string(),
        })
      ),
      url: z.string(),
    })
  ),
});

// TYPES

export type GetRibbonDataResponse = z.infer<typeof RibbonSchema>;

export type GetReporterCuratedData = z.infer<typeof ReporterCuratedDataSchema>;

export type GetReporterClinvarData = z.infer<typeof ReporterClinvarDataSchema>;

export type RibbonJsonRecord = {
  [key: string]: ACMGDesc;
};

type ACMGDesc = {
  short_desc: string;
};

export type GetCuratedClinVarVariantContentParams = {
  urlTermIds: SearchedTermIds;
  urlString: string;
};
