import {
  GetRibbonDataResponse,
  RibbonJsonRecord,
} from "../network/reporter/types";
import { ClinVarRecord, CurationRecordVariant } from "./articles";

export const VariantSubTypesDisplay = {
  pathogenic: "Pathogenic",
  likely_pathogenic: "Likely Pathogenic",
  benign: "Benign",
  likely_benign: "Likely Benign",
  vus: "Variant of uncertain significance",
  uncurated: "We don't have an assessment for this variant yet",
  conflict: "Conflict",
};

export interface GeneItem {
  text: string;
}

export interface HeaderVariantProps {
  variant: CurationRecordVariant | undefined;
  variants: CurationRecordVariant[] | undefined;
}

export interface Assessment {
  classification: string;
  date: string;
  pathogenicity: string;
  detail: string;
}

export interface ClinvarItem {
  id: number;
  text: string;
  detail: string;
  pathogenicity: string;
}

export interface ClinvarMetaData {
  description: string;
  reviewStatus: string;
  submissions: number;
  mostRecent: string;
}

export interface ACMGRecordsProps {
  acmgRecords: RibbonJsonRecord;
}

export interface CategoriesProps {
  acmgRecords: RibbonJsonRecord;
}

export type AssessmentBarProps = {
  curatedVariantData: CurationRecordVariant | undefined;
  ribbonData: GetRibbonDataResponse | undefined;
};

export type AssessmentBarLargeProps = AssessmentBarProps & {
  selectedVariant: CurationRecordVariant | undefined;
};

export interface AssessmentClinvarProps {
  clinvarRecords: ClinVarRecord[];
}
