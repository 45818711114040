import React from "react";
import { VariantResult } from "../../../models/variant-result";
import { useAppSelector } from "../../../store/hooks";
import { selectUrlTermIds } from "../../../store/selectors/urlSelectors";
import { AssessmentBarType } from "../../../types/articles";
import {
  IconBenign,
  IconPathogenic,
  IconVus,
} from "../../common/icons/VariantAssessments";
import AssessmentBarLarge from "./assessment-bar-large";
import AssessmentBarSmall from "./assessment-bar-small";
import {
  useLazyGetReporterCuratedDataQuery,
  useLazyGetRibbonDataQuery,
} from "../../../network/reporter/reporter";

export const iconMap: {
  benign: React.JSX.Element;
  pathogenic: React.JSX.Element;
  likely_pathogenic: React.JSX.Element;
  likely_benign: React.JSX.Element;
  conflict: React.JSX.Element;
  vus: React.JSX.Element;
  [key: string]: React.JSX.Element; // Index signature
} = {
  benign: <IconBenign />,
  likely_benign: <IconBenign />,
  pathogenic: <IconPathogenic />,
  likely_pathogenic: <IconPathogenic />,
  vus: <IconVus />,
  conflict: <IconVus />,
};

const AssessmentBarWrapper: React.FC<{
  type: AssessmentBarType;
}> = ({ type }) => {
  const { urlTermBooleans, urlCats, urlSigTerms } = useAppSelector(
    (state) => state.url
  );
  const urlTermIds = useAppSelector((state) => selectUrlTermIds(state));
  const { selectedVariant } = useAppSelector((state) => state.articles);
  const [triggerRibbonData, { data: ribbonData }] = useLazyGetRibbonDataQuery();
  const [triggerCuratedData, { data: curatedData }] =
    useLazyGetReporterCuratedDataQuery();
  React.useEffect(() => {
    if (selectedVariant && selectedVariant.gene) {
      void triggerRibbonData(selectedVariant, true);
    }
  }, [selectedVariant]);

  React.useEffect(() => {
    void triggerCuratedData(
      {
        urlTermIds: urlTermIds,
        urlString: urlTermIds.toString(),
      },
      true
    );
  }, [urlTermIds, urlTermBooleans, urlCats, urlSigTerms]);

  const curatedVariantData = selectedVariant
    ? curatedData?.curationRecords.variants.find(
        (v) =>
          VariantResult.variantCasing(v.id) ===
          VariantResult.variantCasing(selectedVariant.id)
      )
    : undefined;

  switch (type) {
    case AssessmentBarType.LARGE:
      return (
        <AssessmentBarLarge
          selectedVariant={selectedVariant}
          curatedVariantData={curatedVariantData}
          ribbonData={ribbonData}
        />
      );
    case AssessmentBarType.SMALL:
      return (
        <AssessmentBarSmall
          curatedVariantData={curatedVariantData}
          ribbonData={ribbonData}
        />
      );
    default:
      return null;
  }
};

export default AssessmentBarWrapper;
