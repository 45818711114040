import { CurationRecordVariant } from "../../types/articles";
import { api } from "../api";
import {
  GetCuratedClinVarVariantContentParams,
  GetReporterClinvarData,
  GetReporterCuratedData,
  GetRibbonDataResponse,
  RibbonSchema,
} from "./types";
import { generateCuratedRouteParams } from "./util";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReporterCuratedData: builder.query<
      GetReporterCuratedData,
      GetCuratedClinVarVariantContentParams
    >({
      async queryFn(params, queryApi, _extraOptions, fetchWithBaseUrl) {
        const { gene: geneString, variant: variantString } =
          generateCuratedRouteParams(params, queryApi);
        const result = await fetchWithBaseUrl({
          url: `/reporter/related/curated?${[variantString, geneString].join(
            "&"
          )}`,
          method: "GET",
        });
        if (result.error) {
          return { error: result.error };
        }
        const castedResult = result.data as GetReporterCuratedData;
        return {
          data: {
            curationRecords: castedResult.curationRecords,
          },
        };
      },
    }),
    getReporterClinvarData: builder.query<
      GetReporterClinvarData,
      GetCuratedClinVarVariantContentParams
    >({
      async queryFn(params, queryApi, _extraOptions, fetchWithBaseUrl) {
        const { gene: geneString, variant: variantString } =
          generateCuratedRouteParams(params, queryApi);
        const result = await fetchWithBaseUrl({
          url: `/reporter/related/clinvar?${[variantString, geneString].join(
            "&"
          )}`,
          method: "GET",
        });
        if (result.error) {
          return { error: result.error };
        }
        const castedResult = result.data as GetReporterClinvarData;
        return {
          data: {
            clinVarRecords: castedResult.clinVarRecords,
          },
        };
      },
    }),
    getRibbonData: builder.query<GetRibbonDataResponse, CurationRecordVariant>({
      query: (variant) => {
        return {
          url: `/reporter/ribbon/${variant.gene}/${variant.id}`,
          method: "GET",
        };
      },
      transformResponse: (response) => RibbonSchema.parse(response),
    }),
  }),
});

export const {
  useLazyGetRibbonDataQuery,
  useLazyGetReporterCuratedDataQuery,
  useLazyGetReporterClinvarDataQuery,
} = extendedApi;
